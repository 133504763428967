<template>
  <cks-combined-route @active="refreshActive">
    <cks-page-wrap style="padding-bottom: 100px;">
      <cks-combined-table :disableDown="true" :row-style="setStyle" :isTableSearch="true" ref="table" cacheKey="testcache" @load="load"
        @selection-change="handleSelectionChange" @refreshConditions="refreshConditions">
        <template #btns>
          <el-button type="primary" @click="$router.push({ name: 'joinUsEdit'})">新建</el-button>
        </template>
        <!-- <el-table-column :selectable="selectable" type="selection" fixed="left"></el-table-column> -->
        <cks-table-column
          stringify
          v-model="conditions"
          @search="handleSearch"
          :tableHeaders="tableHeaders"
          @changeRouter="changeRouter"
          :tableData="tableData"
          :tableOptions="tableOptions"
          v-if="refreshTableHearder"
        >
          <template #status="{row, index}">
            <el-switch
              v-loading="row.switchLoading"
              v-if="index > 0"
              v-model="row.status"
              active-value="Y"
              inactive-value="N"
              active-text="有效"
              inactive-text="失效"
              @change="changeSwitch(row.status, row)"
              style="--el-switch-on-color: #2F51FF;"
            >
            </el-switch>
          </template>
          <template #jobResponsibilities="{row}">
            <div style="max-height: 50px; overflow: hidden;" v-html="row.jobResponsibilities"></div>
          </template>
          <template #requirements="{row}">
            <div style="max-height: 50px; overflow: hidden;" v-html="row.requirements"></div>
          </template>
          <template #none="{row}">
            <el-link @click="up(row.id)" class="sty-right" type="primary">上移</el-link>
            <el-link @click="down(row.id)" class="sty-right" type="success">下移</el-link>
            <el-link @click="first(row.id)" class="sty-right" type="warning">置顶</el-link>
            <el-link @click="last(row.id)" class="sty-right" type="danger">置底</el-link>
          </template>
        </cks-table-column>
      </cks-combined-table>
    </cks-page-wrap>
  </cks-combined-route>
</template>

<script>
import { createTableProps, showSuccess } from '@/utils';
import {
  toRefs, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import apis from '@/request/apis';

export default {
  setup() {
    const getDict2 = () => ({
      value: {
        options: [
          {
            label: 'Y',
            name: '有效',
          },
          {
            label: 'N',
            name: '失效',
          },
        ],
      },
    });
    const data = reactive({
      loading: false,
      conditions: [],
      tableOptions: {
        status: {
          editTrasferKey: 'status',
          isSlotKey: true,
          dict: getDict2,
        },
        jobResponsibilities: {
          isSlotKey: true,
          disableForTableSearch: true,
        },
        requirements: {
          isSlotKey: true,
          disableForTableSearch: true,
        },
        none: {
          disableForTableSearch: true,
          isSlotKey: true,
          fixed: true,
          minWidth: '250px',
        },
      },
      tableHeaders: [
        {
          prop: 'jobName', label: '工作名称', selected: true, required: true,
        },
        {
          prop: 'status', label: '状态', selected: true, required: false,
        },
        {
          prop: 'payDownRange', label: '薪资下限', selected: true, required: false,
        },
        {
          prop: 'payUpRange', label: '薪资上限', selected: true, required: false,
        },
        {
          prop: 'address', label: '工作地点', selected: true, required: false,
        },
        {
          prop: 'eduBack', label: '学历要求', selected: true, required: false,
        },
        {
          prop: 'workYears', label: '工作年限', selected: true, required: false,
        },
        {
          prop: 'jobResponsibilities', label: '岗位职责', selected: true, required: false,
        },
        {
          prop: 'requirements', label: '任职要求', selected: true, required: false,
        },
        {
          prop: 'none', label: '操作', selected: true, required: false,
        },
      ],
    });

    const tableProps = createTableProps({
      loadApi: apis.joinUs.list,
      params: () => ({ conditions: data.conditions }),
      refreshConditions: () => { data.conditions = []; },
    });

    const router = useRouter();
    function changeRouter(row) {
      router.push({
        name: 'joinUsEdit',
        params: {
          id: row.id,
        },
      });
    }

    const changeSwitch = async (val, row) => {
      try {
        row.switchLoading = true;
        await apis.joinUs.changeStatus({
          id: row.id,
          status: val,
        });
        showSuccess('更新成功');
      } finally {
        row.switchLoading = false;
      }
    };
    const up = async (id) => {
      try {
        await apis.joinUs.up({ id });
        showSuccess('更新成功');
        tableProps.refresh();
      } catch {}
    };
    const down = async (id) => {
      try {
        await apis.joinUs.down({ id });
        showSuccess('更新成功');
        tableProps.refresh();
      } catch {}
    };
    const first = async (id) => {
      try {
        await apis.joinUs.first({ id });
        showSuccess('更新成功');
        tableProps.refresh();
      } catch {}
    };
    const last = async (id) => {
      try {
        await apis.joinUs.last({ id });
        showSuccess('更新成功');
        tableProps.refresh();
      } catch {}
    };

    return {
      ...toRefs(data),
      ...toRefs(tableProps),
      changeRouter,
      changeSwitch,
      up,
      down,
      first,
      last,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep ::-webkit-scrollbar {
  display: block;
  background-color: rgb(219, 219, 219);
}
.el-input {
  --el-input-focus-border: #2F51FF;
}
::v-deep .el-switch__label.is-active {
  color: #2F51FF;
}
.sty-right {
  margin-right: 20px;
}
</style>
